'use client';

import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import { Auction, Item } from '@/api';
import Link from 'next/link';
import useAuction from '@/hooks/useAuction';
import { usePathname, useRouter } from 'next/navigation';
import { useFavorite } from '@/hooks/api/useFavorite';
import useAuth from '@/contexts/AuthContext';
import useUnAuth from '@/contexts/UnAuthContext';
import { useMobile } from '@/hooks/useMobile';
import { getPoster } from '@/app/utils/item-utils';
import i18next from 'i18n';
import Price from '../general/Price';
import BidTimer from '../general/BidTimer';
import ProductBadge from './product/ProductBadge';
import { ResponsiveImage } from '../general/ResponsiveImage';
import ShareModal from './ShareModal';
import ArtistTitle from '../general/ArtistTitle';
import SmallLogo from '../general/LogoSmall';

const ProductTile = ({ fullWidth, data }: { fullWidth?: boolean, data: Item }) => {
    const isMobile = useMobile();
    const firstImage = useMemo(() => getPoster(data.images), [data]);
    const router = useRouter();
    const pathname = usePathname();
    const { add, remove, isFavorite } = useFavorite();
    const { user } = useAuth();
    const [showShare, setShowShare] = useState<boolean>(false);
    const { showSignUpOrLogin } = useUnAuth();

    const { hasAuction, auctionOver, auctionType, comingSoon, auction, endTime } = useAuction(data);

    const renderPrivateImage = (data.private && !user);

    return (
        <>
            <div
                className={clsx(
                    'relative h-[440px] overflow-hidden rounded-[8px] border-[1px] border-[rgba(0, 0, 0, 0.10)] my-1 shadow-default flex flex-col justify-between text-center mx-[0.31rem] md:mb-1 mb-8',
                    fullWidth ? '' : '100%',
                    'group/item')}>
                <Link prefetch={false} className="no-hover" href={`/item/${data.slug}`}>
                    <div
                        className="absolute top-0 right-0 left-0 bg-black opacity-0 h-[260px] transition-opacity  group-hover/item:opacity-[0.33] touch-none" />
                </Link>
                {data.trending && <ProductBadge className="absolute right-[10px] top-[10px]" type="trending" />}
                <Link prefetch={false} className="no-hover" href={`/item/${data.slug}`}>
                    {(renderPrivateImage && !data.private_preview_image_url) ? <SmallLogo className="w-full h-[260px] p-12 [&>path]:fill-blue2" /> : <ResponsiveImage
                        imageWidth={258}
                        alt=""
                        className="w-full h-[260px] object-cover"
                        style={{ background: '#F5F5F5' }}
                        src={renderPrivateImage ? data.private_preview_image_url : firstImage?.url}
                    />
                    }
                </Link>

                <div className="absolute top-[192px] right-0 hidden justify-end items-center w-[72px] p-[12px] hover-hover:flex">
                    <div className="flex flex-row items-center justify-center transition-opacity opacity-0 group-hover/item:opacity-100 bg-white rounded-full w-[48px] h-[48px]">
                        <img
                            alt="TheRealest"
                            className={clsx('w-[22px] h-[22px] cursor-pointer')}
                            src={!isFavorite(data.id) ? '/assets/icons/like-icon.svg' : '/assets/icons/like-icon-full.svg'}
                            onClick={async (e) => {
                                e.stopPropagation();
                                if (!user) {
                                    if (isMobile) {
                                        router.push(`/mobile/signup?redirect=${pathname}`);
                                    } else {
                                        showSignUpOrLogin('signup');
                                    }
                                } else if (isFavorite(data.id)) {
                                    remove(data.id);
                                } else {
                                    add(data.id);
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="p-[8px] py-[14px] md:p-[16px] h-[100%] flex flex-col w-full justify-between">
                    <div>
                        <h3 className="text=[20px] leading-[22px] md:text-[24px] md:leading-[26px] cursor-pointer font-[500] font-heading line-clamp-2 mb-[8px]"><Link prefetch={false} href={`/item/${data.slug}`}>{(data.private && !user) ? i18next.t('item-text.private-title') : data.name}</Link></h3>
                        <ArtistTitle artist={data.artists[0]} variant={0} />
                    </div>
                    <Link prefetch={false} className="no-hover" href={`/item/${data.slug}`}>
                        {hasAuction && !auctionOver && auctionType === Auction.TypeEnum.Auction &&
                            <div>
                                <Price className="text-[22px] leading-[22px] font-[500] font-secondary mb-[12px]" justification="C" number={auction?.current_amount ?? '0'} />
                                <div
                                    className="flex flex-row justify-center items-center capitalize text-[14px] font-[500] font-secondary text-green4">
                                    <span className="flex items-center">
                                        <span className="">{auction?.amount_of_bids} bids</span>
                                    </span>
                                    {endTime && (
                                        <>
                                            <div className="mx-2 h-[10px] w-[1px] bg-[#DBDBDB]" />
                                            <span className="flex items-center">
                                                <span className=""><BidTimer dueDate={endTime} /></span>
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                        }

                        {hasAuction && !auctionOver && auctionType === Auction.TypeEnum.BuyNow &&
                            <div>
                                <Price className="text-[22px] leading-[22px] font-[500] font-secondary mb-[12px]" justification="C" number={auction?.buy_now_amount ?? '0'} />
                                <div
                                    className="flex flex-row justify-center items-center capitalize text-[16px] font-[500] font-secondary text-red2">
                                    {i18next.t('item-text.buy-now')}
                                </div>
                            </div>
                        }

                        {hasAuction && auction?.has_sold && auctionOver &&
                            <div>
                                <Price className="text-[22px] leading-[22px] font-[500] font-secondary mb-[12px] " justification="C" number={(auction?.buy_now_amount || auction?.current_amount) ?? '0'} />
                                <div
                                    className="flex flex-row justify-center items-center capitalize text-[14px] font-[500] font-secondary text-grey15">
                                    {i18next.t('item-text.sold')}
                                </div>
                            </div>
                        }

                        {comingSoon &&
                            <div>
                                <div className="text-[18px] text-grey15 leading-[100%] font-[500] font-secondary !mt-auto mb-[8px]">{i18next.t('item-text.coming-soon')}</div>
                                <div
                                    className="flex flex-row justify-center items-center text-[14px] text-grey15 font-[400] font-secondary">
                                    <BidTimer dueDate={comingSoon.startTime} />
                                </div>
                            </div>
                        }
                    </Link>
                </div>
            </div>
            <ShareModal product={data} show={showShare && !isMobile} onHide={() => setShowShare(false)} />
        </>
    );
};

export default ProductTile;
